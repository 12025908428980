@import "variables";
@import "mixins";
@import "mobile";

:root {
  --av-font-family: "Lato", sans-serif;
  --av-font-family-monospace: monospace;
  --av-font-family-serif: serif;

  --av-background-color: rgb(11, 34, 102);

  --av-color-primary: linear-gradient(
    150deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  --av-color-primary-contrast: white;
  --av-color-accent: #{$blue};
  --av-color-accent-dark: #{darken($blue, 20%)};
  --av-color-accent-contrast: white;
  --av-color-danger: #{$red};
  --av-color-danger-contrast: white;
  --av-color-success: #{$green};
  --av-color-success-dark: #{darken($green, 10%)};
  --av-color-success-contrast: var(--av-font-color-dark);
  --av-color-warning: #{$orange};
  --av-color-warning-contrast: #{darken($orange, 40%)};
  --av-color-info: #{$blue-light};
  --av-color-info-contrast: #{darken($blue, 40%)};
  --av-color-gray: #{$gray};
  --av-color-gray-light: #{$gray-light};
  --av-color-gray-lighter: #{$gray-lighter};
  --av-color-gray-lightest: #{$gray-lightest};
  --av-color-gray-dark: #{$gray-dark};
  --av-color-gray-darker: #{$gray-darker};
  --av-color-gray-darkest: #{$gray-darkest};
  --av-color-black: #000;
  --av-color-white: #fff;
  --av-color-focus-outline: var(--av-color-accent);

  --av-font-color-dark: #{$gray-darkest};
  --av-font-color-light: white;
  --av-font-color: var(--av-font-color-light);
  --av-font-size: 15px;
  --av-font-muted-opacity: 0.6;

  --av-link-color: var(--av-font-color);

  --av-border-radius-container: 10px;
  --av-border-radius-button: 5px;

  --av-card-background-color: white;
  --av-card-background-blur-color: #{transparentize(white, 0.2)};
  --av-card-background-secondary-color: var(--av-color-gray-lighter);
  --av-card-color: var(--av-font-color-dark);
  --av-card-border-color: #e6e6e6;
  --av-card-border-top-color: white;
  --av-card-separator-color: #{$gray-light};
  --av-card-form-input-background-color: #{$gray-light};
  --av-card-link-color: var(--av-color-accent);
  --av-card-menu-item-color: var(--av-card-link-color);
  --av-card-menu-item-background-color-hover: rgba(0, 0, 0, 0.06);
  --av-card-menu-item-background-color-active: rgba(0, 0, 0, 0.12);
  --av-card-menu-item-background-color-active-hover: rgba(0, 0, 0, 0.18);

  --av-separator-color: var(--av-separator-color-light);
  --av-separator-color-light: rgba(255, 255, 255, 0.5);
  --av-separator-color-dark: rgba(0, 0, 0, 0.5);

  --av-box-shadow-container: 0 13px 60px -20px rgba(0, 0, 0, 0.6);

  --av-form-input-background-color: #{transparentize($gray, 0.7)};
  --av-form-input-color: var(--av-font-color);
  --av-form-input-border-radius: var(--av-border-radius-button);
  --av-form-input-search-border-radius: 20px;

  --av-spinner-color-light: white;
  --av-spinner-color-dark: #{$gray-darkest};

  --av-menu-item-color: var(--av-link-color);
  --av-menu-item-border-radius: var(--av-border-radius-button);
  --av-menu-item-background-color-hover: #{transparentize(white, 0.9)};
  --av-menu-item-background-color-active: #{transparentize(white, 0.8)};

  --av-table-row-hover: #{$gray};
  --av-table-row-active: #{$gray};
  --av-table-row-border-color: none;
  --av-table-row-border-width: 0;

  --av-tootlip-background-color: black;
  --av-tooltip-color: white;

  --av-tile-background-color: rgba(0, 0, 0, 0.5);
  --av-tile-background-surface-color: rgba(0, 0, 0, 0.8);
  --av-tile-color: white;
  --av-tile-border-color: rgba(255, 255, 255, 0.2);
  --av-tile-border-width: 1px;

  --av-backdrop-color: rgba(0, 0, 0, 0.8);

  //not used for now
  //--av-background-color-tray: black;
  //--av-color-tray: white;
}

:root[data-av-background="light"] {
  --av-separator-color: var(--av-separator-color-dark);
  --av-menu-item-background-color-hover: rgba(0, 0, 0, 0.1);
  --av-menu-item-background-color-active: rgba(0, 0, 0, 0.2);
}

:root[data-av-theme="dark"] {
  --av-card-background-color: rgb(40, 40, 50);
  --av-card-background-blur-color: rgba(40, 40, 50, 0.85);
  --av-card-background-secondary-color: #{darken($gray-darker, 10%)};
  --av-card-color: white;
  --av-card-separator-color: var(--av-separator-color-dark);
  --av-card-border-color: #{$gray-darkest};
  --av-card-border-top-color: #666;
  --av-card-form-input-background-color: #{$gray-darkest};
  --av-card-menu-item-background-color-hover: rgba(255, 255, 255, 0.06);
  --av-card-menu-item-background-color-active: rgba(255, 255, 255, 0.12);
  --av-card-menu-item-background-color-active-hover: rgba(255, 255, 255, 0.18);
  --av-table-row-active: #{$gray-dark};
  --av-table-row-hover: #{$gray-dark};

  button[avbutton]:after,
  a[avbutton]:after {
    background-color: rgba(255, 255, 255, 0.2);
  }

  div.toast-container {
    div.ngx-toastr {
      &.toast-info {
        background-image: url("/core-ui/assets/images/info-light.svg");
      }
    }
  }
}

html,
body {
  font-family: var(--av-font-family);
  height: 100%;
  color: var(--av-font-color-dark);
  margin: 0;
  font-size: var(--av-font-size);
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
}

hr {
  margin: 20px 0;
  border-bottom: 0;
  border-style: solid;
  border-color: var(--av-separator-color);
}
a {
  color: var(--av-link-color);
  font-weight: bold;
}
:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
}
.toast-container .toast {
  box-shadow: none !important;
}
.panel-body-row-bkg-cold {
  background-color: var(--av-card-background-secondary-color);
  padding: 5px;
  border-radius: var(--av-border-radius-button);
}

.panel-body-row-bkg-success {
  background-color: var(--av-color-success);
  padding: 5px;
  border-radius: var(--av-border-radius-button);
  color: var(--av-color-success-contrast);
}

.panel-footer-two {
  button {
    width: 49%;
    &:last-child {
      float: right;
    }
  }
}

.text-right {
  text-align: right;
}

.panel-error-logo {
  padding: 2px 5px;
}

.popup-container {
  position: relative;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  @include animate(spin, 1.5s, infinite, linear);
}
.container {
  padding: 50px 40px 40px 40px;
  z-index: 15;
  position: relative;
}

.container-pad-md {
  padding: 20px;
}

.container-pad-lg {
  padding: 40px;
}

.container-pad-sm {
  padding: 10px;
}

.container-col-md {
  padding: 0 20px;
}

.container-col-sm {
  padding: 0 10px;
}

.text-center {
  text-align: center;
}
.text-danger {
  color: var(--av-color-danger);
}
.text-success {
  color: var(--av-color-success);
}
.text-warning {
  color: var(--av-color-warning);
}
.text-muted {
  opacity: var(--av-font-muted-opacity);
}
.logo-text {
  position: fixed;
  bottom: 5px;
  right: 20px;
  z-index: 1;
  text-align: center;
  width: 140px;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.logo-icon {
  position: fixed;
  right: -20px;
  bottom: -75px;
  z-index: 0;
  img {
    width: 370px;
  }
}
.version-info {
  position: fixed;
  bottom: 10px;
  left: 10px;
  font-size: 0.6em;
  opacity: 0.5;
  z-index: 10;
}

h1.title {
  position: relative;
  font-weight: bold;
}

.pull-right {
  float: right;
}

.fx-row {
  display: flex !important;
  flex-direction: row;
}

.fx-gap-10 {
  gap: 10px;
}

.fx-gap-20 {
  gap: 20px;
}

.fx-wrap {
  flex-wrap: wrap;
}

.fx-inline {
  display: inline-flex;
}

.fx-column {
  display: flex !important;
  flex-direction: column;
}

.fx-justify-start {
  justify-content: flex-start;
}

.fx-justify-end {
  justify-content: flex-end;
}

.fx-justify-center {
  justify-content: center;
}

.fx-justify-space-between {
  justify-content: space-between;
}

.fx-justify-space-around {
  justify-content: space-around;
}

.fx-justify-space-evenly {
  justify-content: space-evenly;
}

.fx-align-start {
  align-items: flex-start;
}

.fx-align-end {
  align-items: flex-end;
}

.fx-align-center {
  align-items: center;
}

.fx-align-stretch {
  align-items: stretch;
}

.fx-align-baseline {
  align-items: baseline;
}

.fx-flex-1 {
  flex: 1;
}
.fx-flex-1-half {
  flex: 1.5;
}
.fx-flex-2 {
  flex: 2;
}
.fx-flex-3 {
  flex: 3;
}
.fx-flex-4 {
  flex: 4;
}
.fx-flex-5 {
  flex: 5;
}
.fx-row-responsive {
  flex-direction: row;
  display: flex !important;
}

@media screen and (max-width: $tablet-breakpoint) {
  div.fx-row-responsive {
    flex-direction: column;
    align-items: stretch;
  }
}

.form-error {
  background-color: var(--av-color-danger);
  color: var(--av-color-danger-contrast);
  border-radius: 2px;
  font-size: 0.8em;
  margin-left: 20px;
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.no-margin {
  margin: 0;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-0 {
  margin-top: 0;
}

.nav-action {
  position: absolute;
  top: 10px;
  right: 10px;
}

.av-picker {
  border-radius: var(--av-border-radius-button);
  &.dp-popup {
    background-color: var(--av-card-background-color);
    border-width: var(--av-card-border-width);
    border-style: solid;
    border-color: var(--av-card-border-color);
    border-top-color: var(--av-card-border-top-color);
  }
  button {
    border-radius: var(--av-border-radius-button);
    padding: 0;
    color: var(--av-card-color);
    background: transparent;
    margin: 2px;
    font-size: 0.9em;
    border: 0;
    &:not(.dp-selected):hover {
      background-color: var(--av-card-menu-item-background-color-hover);
    }
    &.dp-current-day {
      border: 1px solid var(--av-color-accent);
    }
  }
  dp-time-select .dp-time-select-controls {
    background: transparent;
    color: var(--av-card-color);
  }
  .dp-selected {
    background: var(--av-color-accent);
  }
  .dp-weekdays {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .dp-calendar-weekday {
    text-transform: uppercase;
    width: auto;
    border-left: 0px;
    border-bottom: 0;
    font-size: 0.7em;
    font-weight: bold;
    opacity: 0.6;
    padding: 4px;
  }
  .dp-calendar-week {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
  }
  .dp-calendar-wrapper {
    border: 0px;
    padding: 5px;
  }
  .dp-calendar-nav-container {
    border: 0px;
    background: transparent;
  }
  .dp-day-calendar-container {
    background: transparent;
  }
  .dp-picker-input {
    width: 100%;
    padding: 7px 10px;
    border: 0;
    border-radius: var(--av-border-radius-button);
    font-size: var(--av-font-size);
    box-sizing: border-box;
    background: var(--av-card-form-input-background-color);
    color: var(--av-card-color);
  }
}

.dp-time-select-controls {
  color: var(--av-font-color-dark);
}

.ripple-container {
  position: relative;

  &::after {
    @include rings(3s, 0s);
  }

  &::before {
    @include rings(3s, 0.5s);
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  @include animate(blink, 2s, infinite, linear);
}

div.toast-container {
  div.ngx-toastr {
    box-shadow: none;
    border-radius: var(--av-border-radius-button);
    &.toast-info {
      box-shadow: var(--av-box-shadow-container);
      background-color: var(--av-card-background-color);
      // border: 1px solid var(--av-card-border-color);
      // border-top-color: var(--av-card-border-top-color);
      color: var(--av-card-color);
      background-image: url("/modules/shared/assets/images/info-dark.svg");
    }
    &.toast-error {
      background-color: var(--av-color-danger);
      color: var(--av-color-danger-contrast);
      background-image: url("/modules/shared/assets/images/close-round-light.svg");
    }
    &.toast-success {
      background-color: var(--av-color-success);
      color: var(--av-color-success-contrast);
      background-image: url("/modules/shared/assets/images/tick-round-dark.svg");
    }
    &.toast-warning {
      background-color: var(--av-color-warning);
      color: var(--av-color-warning-contrast);
      background-image: url("/modules/shared/assets/images/alert-dark.svg");
    }
  }
}

.stream-fullscreen {
  left: 0px !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0px !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 100 !important;
}

.pulse-danger {
  @include animate(pulse-shadow, 2s, infinite, linear);
}
@-moz-keyframes pulse-shadow {
  0% {
    box-shadow: 0 0 0 $red;
  }
  50% {
    box-shadow: 0 0 10px 8px $red;
  }
  100% {
    box-shadow: 0 0 0 $red;
  }
}

@-webkit-keyframes pulse-shadow {
  0% {
    box-shadow: 0 0 0 $red;
  }
  50% {
    box-shadow: 0 0 10px 8px $red;
  }
  100% {
    box-shadow: 0 0 0 $red;
  }
}

@keyframes pulse-shadow {
  0% {
    box-shadow: 0 0 0 $red;
  }
  50% {
    box-shadow: 0 0 10px 8px $red;
  }
  100% {
    box-shadow: 0 0 0 $red;
  }
}

.hidden {
  display: none !important;
}

.pill {
  font-size: 0.8em;
  background-color: var(--av-color-gray-dark);
  border-radius: 20px;
  padding: 2px 10px 4px;
  color: var(--av-font-color-light);
  margin-right: 10px;

  &.pill-warn {
    background-color: var(--av-color-warning);
    color: var(--av-color-warning-contrast);
  }
  &.pill-danger {
    background-color: var(--av-color-danger);
    color: var(--av-color-danger-contrast);
  }
  &.pill-success {
    background-color: var(--av-color-success);
    color: var(--av-color-success-contrast);
  }
  &.pill-accent {
    background-color: var(--av-color-accent);
    color: var(--av-color-accent-contrast);
  }
  &.pill-inline-block {
    display: inline-block;
  }
}

.av-calendar {
  display: flex;
  flex-direction: column;
  dp-time-select {
    border: 0;
    border-top: 1px solid var(--av-card-separator-color);
    border-radius: 0;
    .dp-time-select-controls {
      background-color: transparent;
    }
  }
  .dp-nav-header-btn,
  .dp-calendar-month {
    color: var(--av-card-menu-item-color);
  }
  dp-calendar-nav .dp-calendar-nav-container {
    height: 38px;
  }

  .dp-nav-header-btn,
  .dp-calendar-month,
  .dp-calendar-nav-left,
  .dp-calendar-nav-right,
  .dp-time-select-control button,
  .dp-calendar-day:not(.dp-selected) {
    transition: background-color 0.2s ease-out;
    &:hover {
      background-color: var(--av-card-menu-item-background-color-hover);
    }
  }

  .dp-nav-header-btn {
    padding: 10px;
    font-size: 16px;
  }
  .dp-calendar-nav-left,
  .dp-calendar-nav-right {
    padding: 10px 15px;
    margin: 0 5px;
  }
  .dp-calendar-nav-left:before {
    margin-left: -5px;
    content: "";
  }
  .dp-calendar-nav-right:before {
    margin-left: -7px;
  }

  .dp-calendar-month.dp-selected {
    color: var(--av-color-white);
  }
}

.tick {
  border-radius: 10px;
  width: 20px;
}
.tick-yes {
  background: var(--av-color-success);
}

.tick-no {
  background: var(--av-color-gray);
}

.container-overflown {
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  .container-overflown-body {
    position: relative;
    box-sizing: border-box;
    height: 100%;
  }
}

.theme-talkdesk .container-overflown {
  height: calc(100vh - 90px - #{$talkdesk-header-height});
}

@media screen and (max-width: $tablet-breakpoint) {
  div.container-responsive,
  section.container-responsive {
    padding: 50px 10px 15px;
  }
  div.container-overflown-responsive,
  section.container-overflown-responsive {
    height: auto;
    padding: 50px 10px 15px;
    .container-overflown-body {
      height: 100vh;
    }
    .card-body-list.open + .card-body-list-pager {
      right: 0;
    }
    .card-body-list.open {
      bottom: 50%;
      right: 0;
    }
    .card-body-list-details {
      border-top: 1px solid var(--av-card-separator-color);
      width: 100% !important;
      top: 50%;
      bottom: 40px;
    }
  }
}

.container-filters {
  width: 280px;
  padding-right: 16px;
  max-height: calc(100vh - 170px);
}

@media screen and (max-width: $tablet-breakpoint) {
  .container-filters.container-filters-responsive {
    width: auto;
    padding-right: 0;
    max-height: initial !important;
    margin-bottom: 10px;
  }
}

.container-filters-overflow {
  overflow: auto;
}

.container-relative {
  position: relative;
}

.card-body-pager {
  .card-body-list {
    bottom: 40px;
  }
}

.card-body-list {
  position: absolute;
  top: 5px;
  bottom: 5px;
  overflow: auto;
  left: 5px;
  right: 0;
  padding-right: 5px;
  transition: right 0.2s ease-out;
  &.open {
    right: 50%;
    + .card-body-list-pager {
      right: 50%;
    }
  }
}

.card-body-list-pager {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  transition: right 0.2s ease-out;
}

.card-body-list-details {
  flex: 1;
  border-left: 1px solid var(--av-card-separator-color);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}

.full-height {
  height: 100%;
}

.chevron {
  @include chevron();
  transition: all 0.2s ease-out;
  margin: 2px;

  &.chevron-up {
    transform: rotate(-45deg);
  }
}

.av-card-input {
  border: 0;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.provider {
  position: relative;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
}
.provider-exists {
  &:after {
    content: url("/modules/shared/assets/images/tick-light.svg");
    color: var(--av-color-success-contrast);
    background-color: var(--av-color-success);
    width: 24px;
    height: 24px;
    border-radius: 12px;
    position: absolute;
    top: -5px;
    right: -10px;
  }
}

.radio-pill-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
}

.radio-pill-group-item {
  padding: 6px 16px;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease-out;
  input[type="radio"] {
    display: none;
  }
  border-radius: 32px;
  @include darken-on-hover();
  &:after {
    border-radius: 32px;
  }

  &.radio-group-item-active {
    background: var(--av-color-accent);
    color: var(--av-color-accent-contrast);
  }
}

.radio-group {
  display: flex;
  flex-direction: row;
  &.radio-group-dark {
    .radio-group-item {
      border-color: var(--av-font-color-dark);
      &:hover,
      &.radio-group-item-active {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.radio-group-item {
  padding: 5px 20px;
  border: 2px solid var(--av-font-color);
  border-right-width: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background 0.2s ease-out;
  &:hover,
  &.radio-group-item-active {
    background: var(--av-menu-item-background-color-active);
  }
  input[type="radio"] {
    display: none;
  }
  &:first-child {
    border-radius: var(--av-border-radius-button) 0 0
      var(--av-border-radius-button);
  }
  &:last-child {
    border-right-width: 2px;
    border-radius: 0 var(--av-border-radius-button)
      var(--av-border-radius-button) 0;
  }
}

.full-width,
select.full-width,
input.full-width {
  width: 100%;
}

.width-80,
input.width-80 {
  width: 80px;
}

.control-error {
  width: 1.3em; // 20px;
  height: 1.3em; // 20px;
  border-radius: 1em; // 14px;
  background-color: var(--av-color-danger);
  color: var(--av-color-danger-contrast);
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.has-error {
  color: var(--av-color-danger);
}

.filter {
  padding: 5px 0;
  border-bottom: 1px solid var(--av-separator-color);
}

av-autocomplete {
  width: 100%;
  display: block;
  box-sizing: border-box;
  .item av-menu-item.menu-item.menu-item-in-card {
    display: block;
    height: auto;
  }
}

.keyboard {
  border: 1px solid var(--av-card-color);
  display: inline-flex;
  line-height: 1em;
  border-radius: 3px;
  border-bottom: 2px solid var(--av-card-color);
  font-size: 0.8em;
  height: 1em;
  width: 1em;
  text-align: center;
  color: var(--av-card-color);
  align-items: center;
  justify-content: center;

  &.keyboard-tooltip {
    color: var(--av-color-white);
    border-color: var(--av-color-white);
  }
}

.av-drag-over {
  outline: 2px dashed var(--av-color-success);
  &::before {
    content: "";
    background-color: var(--av-color-success);
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 1;
    border-radius: var(--av-border-radius-container);
  }
}

.list {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 15px;
}
